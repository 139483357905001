import { useState, useEffect } from "react";
import logo from "./assets/logo.png";
import logob from "./assets/logo b.gif";
import home from "./assets/home.gif";
import blogo from "./assets/blogo.png";
import bnb from "./assets/bnb.png";
import net from "./assets/net.png";
import tele from "./assets/tele.png";
import twitter from "./assets/twitter.png";
import WalletConnectProvider from "@walletconnect/web3-provider";
import web3 from "web3";
import PacmanLoader from "react-spinners/PacmanLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { ethers, providers, Signer } = require("ethers");
function App() {
  const contractAddress = "0xe51da43AD08C0D01b5F0823e2e415221f075C469";
  const buyAbi = '[{"inputs":[{"internalType":"address","name":"referrer","type":"address"}],"name":"buyGhosts","outputs":[],"stateMutability":"payable","type":"function"}]';
  const compoundAbi = '[{"inputs":[],"name":"hatchGhosts","outputs":[],"stateMutability":"nonpayable","type":"function"}]';
  const cashoutAbi = '[{"inputs":[],"name":"sellGhosts","outputs":[],"stateMutability":"nonpayable","type":"function"}]';
  const getMyMinerAbi = '[{"inputs":[{"internalType":"address","name":"_address","type":"address"}],"name":"getMyMiners","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]';
  const myghostsRewardsAbi = '[{"inputs":[{"internalType":"address","name":"_address","type":"address"}],"name":"ghostRewards","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]';
  const reftiercheckAbi = '[{"inputs":[{"internalType":"address","name":"","type":"address"}],"name":"rewardsTier1","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"","type":"address"}],"name":"rewardsTier2","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"","type":"address"}],"name":"rewardsTier3","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"","type":"address"}],"name":"rewardsTier4","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]';
  const prefix = 'BNB'
  const [connect, setConnect] = useState(false);
  const [buy, setBuy] = useState(false);
  const [isMetamask, setisMetamask] = useState(false);
  const [isWalletConnect, setisWalletConnect] = useState(false);
  const [userAddress, setuserAddress] = useState(null);
  const [realAddress, setrealAddress] = useState(null);
  const [bnbamount, setbnbamount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refadd, setrefadd] = useState("0x0000000000000000000000000000000000000000")
  const [contractAmount, setcontractAmount] = useState(null);
  const [userAmount, setuserAmount] = useState(null);
  const [MinerAmount, setminerAmount] = useState(null);
  const [yourRewards, setyourRewards] = useState(0);
  const [linkchainbits, setlinkchainbits] = useState(null);
  const [refhidden, setrefhidden] = useState(null);
  const [btnclicked, setbtnclicked] = useState(null);
  const [showrefresult, setshowrefresult] = useState(null);
  const [showWarning, setshowWarning] = useState(false);

  useEffect(() => {
  // call api or anything
  isConnectedMetamask();
  isConnectedWC();
  getDetails();
  loadStats();
  //check ref
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const refaddress = urlParams.get('ref');

  if (refaddress != null && refaddress.length == 42) {
    setrefadd(refaddress);
  }

 });

 async function loadStats() {
  if (connect && isMetamask) {
 const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
 const signer = provider.getSigner();
 const address = await signer.getAddress();

//CHECK BALANCE CONTRACT
 await provider.getBalance(contractAddress).then((balance) => {
  // convert a currency unit from wei to ether
  setcontractAmount(ethers.utils.formatEther(balance));
 });

 //CHECK BALANCE USER
 await provider.getBalance(address).then((balance) => {
  // convert a currency unit from wei to ether
  setuserAmount(ethers.utils.formatEther(balance));
 });

 //CHECK HOW MANY GHOSTS
 const contract = new ethers.Contract(contractAddress, getMyMinerAbi, provider);
 const myMiner = await contract.getMyMiners(address);
 console.log(myMiner._hex);
 setminerAmount(parseInt(myMiner._hex, 16));

 //GET GHOSTS REWARDS
 const contract2 = new ethers.Contract(contractAddress, myghostsRewardsAbi, provider);
 const myghostsRewards = await contract2.ghostRewards(address);
 const myghostshex = parseInt(myghostsRewards._hex, 16).toString();
 const weighosts = ethers.utils.formatEther(myghostshex);
 setyourRewards(Number(weighosts).toFixed(6));
  }
 }

  async function getDetails() {
    if (isMetamask) {
      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");

      const signer = provider.getSigner();
      const address = await signer.getAddress();

      setrealAddress(address);
      const userAddress = address.substring(0,5) + "..." + address.substring(38,42);

      setuserAddress(userAddress);
    
      // Always prints the address that I first connected with
    } if (isWalletConnect) {
      const provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        bridge: 'https://bridge.walletconnect.org',
        pollingInterval: 10000,
        chainId: 56,
      });
      if (showWarning == false) {
      toast.warning("walletconnect is in beta.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setshowWarning(true);
      }
      const address = provider.connector.session.accounts.toString();
      const userAddress = address.substring(0, 5) + "..." + address.toString().substring(38, 42);
      setuserAddress(userAddress);
      setrealAddress(address);

      //CHECK BALANCE CONTRACT
      await provider.enable();
      const ethers_provider = new providers.Web3Provider(provider);
      const signer = ethers_provider.getSigner();

      //HOW MANY BNB USER

      const userbalancewc = await signer.getBalance();
      setuserAmount(ethers.utils.formatEther(userbalancewc));
      
      //HOW MANY BNB CONTRACT 

      const contractbalancewc = await ethers_provider.getBalance(contractAddress);
      setcontractAmount(ethers.utils.formatEther(contractbalancewc));

      //HOW MAY MINERS USER
      const contract = new ethers.Contract(contractAddress, getMyMinerAbi, signer);
      const myMiner = await contract.getMyMiners(address);
      console.log(myMiner._hex);
      setminerAmount(parseInt(myMiner._hex, 16));

 //GET GHOSTS REWARDS
      const contract2 = new ethers.Contract(contractAddress, myghostsRewardsAbi, signer);
      const myghostsRewards = await contract2.ghostRewards(address);
      const myghostshex = parseInt(myghostsRewards._hex, 16).toString();
      const weighosts = ethers.utils.formatEther(myghostshex);
      setyourRewards(Number(weighosts).toFixed(6));
    }
  }

async function addNetwork() {
  const chainId = 56 // BSC Mainnet

if (window.ethereum.networkVersion !== chainId) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(chainId) }]
        });
      } catch (err) {
          // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'Smart Chain',
                chainId: web3.utils.toHex(chainId),
                nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
                rpcUrls: ['https://bsc-dataseed.binance.org/']
              }
            ]
          });
        }
      }
    }
}

async function isConnectedWC() {
//  Get Accounts
const provider = new WalletConnectProvider({
  rpc: {
    56: "https://bsc-dataseed.binance.org/",
  },
  bridge: 'https://bridge.walletconnect.org',
  pollingInterval: 10000,
  chainId: 56,
});
const accounts = await provider.connector.connected;
if (accounts == true) { setConnect(true); setisWalletConnect(true); }
}

//wallet connect
async function connectwallet() {
    //  Create WalletConnect Provider
const provider = new WalletConnectProvider({
  rpc: {
    56: "https://bsc-dataseed.binance.org/",
  },
  bridge: 'https://bridge.walletconnect.org',
  pollingInterval: 10000,
  chainId: 56,
});

//  Enable session (triggers QR Code modal)
const resultconnect = await provider.enable();
if (resultconnect != "Error: User closed modal"){
  setConnect(true);
}
}
//metamask
async function metamaskconnect() {
  //  Wrap with Web3Provider from ethers.js
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  // Prompt user for account connections
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  window.location.reload();
}

//check if is connected
async function isConnectedMetamask() {
  const provider = new ethers.providers.Web3Provider(
    (window).ethereum
  );
  const addresses = await provider.listAccounts(); 
  // it doesn't create metamask popup
  if (addresses.length) {
    setConnect(true);
    setisMetamask(true);
  }
  // permission already granted so request account address from metamask
  else {
    console.log("not connected");
  }
}

//INTERACTIVE FUNCTIONS

//Insert coin
async function insertCoin() {
  if (bnbamount > userAmount || bnbamount == 0) {
    if (bnbamount == 0) {
      toast.error("BNB amount is 0", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    } else {
    toast.error("You don't have enough BNB", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
    }
  } else {
  setLoading(true);
  if (isMetamask) {
  //metamask
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(contractAddress, buyAbi, provider);
  const contractExe = await contract.connect(signer);
  try {
  await contractExe.buyGhosts(refadd, { value: ethers.utils.parseEther(bnbamount) });
  } catch (err) {
    console.log({err});
    toast.error("Error, try again", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }
  setLoading(false);
  loadStats();
  } else
  if (isWalletConnect) {
    //WALLETCONNECT
    const provider = new WalletConnectProvider({
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
      },
      bridge: 'https://bridge.walletconnect.org',
      pollingInterval: 10000,
      chainId: 56,
    });
    const ethers_provider = new ethers.providers.Web3Provider(provider);
    const signer = ethers_provider.getSigner();
    const contract = new ethers.Contract(contractAddress, buyAbi, ethers_provider);
    const contractExe = await contract.connect(signer);
    try {
    await contractExe.buyGhosts(refadd, { value: ethers.utils.parseEther(bnbamount) });
    } catch (err) {
      console.log({err});
      toast.error("Error, try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      }
    setLoading(false);
    loadStats();
  }
}
}

//Compound

async function compound() {
  if (isMetamask) {
  setLoading(true);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(contractAddress, compoundAbi, provider);
  const contractExe = await contract.connect(signer);
  try {
  await contractExe.hatchGhosts();
  } catch (err) {
    console.log({err});
    toast.error("Error, try again", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
      }
  setLoading(false);
  loadStats();
  }
  if (isWalletConnect) {
    setLoading(true);
    const provider = new WalletConnectProvider({
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
      },
      bridge: 'https://bridge.walletconnect.org',
      pollingInterval: 10000,
      chainId: 56,
    });
    const ethers_provider = new ethers.providers.Web3Provider(provider);
    const signer = await ethers_provider.getSigner();
    const contract = new ethers.Contract(contractAddress, compoundAbi, ethers_provider);
    const contractExe = await contract.connect(signer);
    try {
    await contractExe.hatchGhosts();
    } catch (err) {
      console.log({err});
      toast.error("Error, try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    setLoading(false);
    loadStats();
  }
}


async function loadingrefresult(tier) {
  if (tier == 1 && isMetamask) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, reftiercheckAbi, provider);
    const tier1 = await contract.rewardsTier1(realAddress);
    console.log("tier1 " + tier1._hex);
    
    setshowrefresult(parseInt(tier1._hex, 16));
  } else if (tier == 1 && isWalletConnect) {
    const provider = new WalletConnectProvider({
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
      },
      bridge: 'https://bridge.walletconnect.org',
      pollingInterval: 10000,
      chainId: 56,
    });
    await provider.enable();
    const ethers_provider = new providers.Web3Provider(provider);
    const signer = ethers_provider.getSigner();
    const contractwc = new ethers.Contract(contractAddress, reftiercheckAbi, signer);
    const tier1 = await contractwc.rewardsTier1(realAddress);
    console.log("tier1 " + tier1._hex);
    setshowrefresult(parseInt(tier1._hex, 16));
  }
  if (tier == 2 && isMetamask) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, reftiercheckAbi, provider);
    const tier2 = await contract.rewardsTier2(realAddress);
    console.log("tier2 " + tier2._hex);
    
    setshowrefresult(parseInt(tier2._hex, 16));
  } else if (tier == 2 && isWalletConnect) {
    const provider = new WalletConnectProvider({
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
      },
      bridge: 'https://bridge.walletconnect.org',
      pollingInterval: 10000,
      chainId: 56,
    });
    await provider.enable();
    const ethers_provider = new providers.Web3Provider(provider);
    const signer = ethers_provider.getSigner();
    const contractwc = new ethers.Contract(contractAddress, reftiercheckAbi, signer);
    const tier2 = await contractwc.rewardsTier2(realAddress);
    console.log("tier2 " + tier2._hex);
    setshowrefresult(parseInt(tier2._hex, 16));
  }
  if (tier == 3) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, reftiercheckAbi, provider);
    const tier3 = await contract.rewardsTier3(realAddress);
    console.log("tier3 " + tier3._hex);
    
    setshowrefresult(parseInt(tier3._hex, 16));
  }  else if (tier == 3 && isWalletConnect) {
    const provider = new WalletConnectProvider({
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
      },
      bridge: 'https://bridge.walletconnect.org',
      pollingInterval: 10000,
      chainId: 56,
    });
    await provider.enable();
    const ethers_provider = new providers.Web3Provider(provider);
    const signer = ethers_provider.getSigner();
    const contractwc = new ethers.Contract(contractAddress, reftiercheckAbi, signer);
    const tier3 = await contractwc.rewardsTier3(realAddress);
    console.log("tier3 " + tier3._hex);
    setshowrefresult(parseInt(tier3._hex, 16));
  }
  if (tier == 4) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, reftiercheckAbi, provider);
    const tier4 = await contract.rewardsTier4(realAddress);
    console.log("tier4 " + tier4._hex);
    
    setshowrefresult(parseInt(tier4._hex, 16));
  }  else if (tier == 4 && isWalletConnect){
    const provider = new WalletConnectProvider({
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
      },
      bridge: 'https://bridge.walletconnect.org',
      pollingInterval: 10000,
      chainId: 56,
    });
    await provider.enable();
    const ethers_provider = new providers.Web3Provider(provider);
    const signer = ethers_provider.getSigner();
    const contractwc = new ethers.Contract(contractAddress, reftiercheckAbi, signer);
    const tier4 = await contractwc.rewardsTier4(realAddress);
    console.log("tier4 " + tier4._hex);
    setshowrefresult(parseInt(tier4._hex, 16));
  }

}

async function cashout() {
  if (isMetamask) {
  setLoading(true);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(contractAddress, cashoutAbi, provider);
  const contractExe = await contract.connect(signer);
  try {
  await contractExe.sellGhosts();
  } catch (err) {
    console.log({err});
    toast.error("Error, try again", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }
  setLoading(false);
  loadStats();
  }
  if (isWalletConnect) {
    setLoading(true);
    const provider = new WalletConnectProvider({
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
      },
      bridge: 'https://bridge.walletconnect.org',
      pollingInterval: 10000,
      chainId: 56,
    });
    const ethers_provider = new ethers.providers.Web3Provider(provider);
    const signer = await ethers_provider.getSigner();
    const contract = new ethers.Contract(contractAddress, cashoutAbi, ethers_provider);
    const contractExe = await contract.connect(signer);
    try {
    await contractExe.sellGhosts();
    } catch (err) {
      console.log({err});
      toast.error("Error, try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    setLoading(false);
    loadStats();
  }
}


  return (
    <div className="App bg-black min-h-screen flex items-center justify-center">
      <ToastContainer
ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
<ToastContainer />
      <div className=" container flex items-center justify-center">
        <div className={connect ?" w-full  lg:w-1/3 text-white relative pt-20 ": " w-full  lg:w-1/3 text-white relative  "}>
          <img src={logo} alt="" />
          <img src={logob} alt="" />
    {
      connect &&      <div className=" flex items-center justify-center w-full mt-10">
         <button
      className=" btn-primary-sm  text-white block lg:hidden  "
      style={{ fontSize: "20px",paddingTop: "10px",
      width: "80%",
      textAlign: "center",
      paddingBottom: "15px" }}
      onClick={()=> window.open(`https://bscscan.com/address/${realAddress}`, "_blank")}
      >
      {userAddress}
    </button>
      </div>
    }
          {!connect ? (
            <>
              <div className=" relative mt-10">
                <img src={home} alt="" />
                <div
                  className=" absolute top-0 left-0 z-10 w-full h-full flex items-center justify-center"
                  style={{ background: "rgba(0,0,0,0.5)" }}
                >
                  <div className="absolute top-0 left-0 z-10 w-full h-full flex items-center justify-center">
                    <button
                      onClick={() => connectwallet()}
                      className=" btn-primary -mt-5 block  lg:hidden"
                      style={{
                        paddingLeft:"30px",
                        paddingRight:"30px"
                      }}
                    >
                      Wallet Connect
                    </button>
                    <button
                      onClick={() => connectwallet()}
                      className=" btn-primary mt-0 hidden lg:block"
                     
                    >
                      Wallet Connect
                    </button>
                    <button
                      onClick={() => metamaskconnect()}
                      className=" btn-primary -mt-5 block  lg:hidden"
                      style={{
                        paddingLeft:"30px",
                        paddingRight:"30px",
                        paddingTop:"34px"
                      }}
                    >
                      Metamask
                    </button>
                    <button
                      onClick={() => metamaskconnect()}
                      className=" btn-primary mt-0 hidden lg:block"
                     style={{paddingTop:"44px"}}
                    >
                      Metamask
                    </button>
                  </div>
                </div>
              </div>
              <div className=" w-full flex items-center justify-center">
                <a href="https://www.certik.com/projects/pac-miner" target="_blank" rel="noreferrer">
                <img
                  src={blogo}
                  alt=""
                  className=" mt-3 w-40"
                  style={{ objectFit: "contain" }}
                />
                </a>
              </div>
            </>
          ) : (
            <>
              <div className=" relative">
                <img src={bnb} alt="" className=" mt-20" />
                <div className=" absolute z-10 top-0 left-0 w-full h-full p-4">
                  <div className=" w-full flex items-center justify-between">
                    <p className=" text-pr">Contract</p>
                    <p className=" text-pr">{Number(contractAmount).toFixed(2)} BNB</p>
                  </div>
                  <div className=" w-full flex items-center justify-between pt-2">
                    <p className=" text-pr">Your Wallet</p>
                    <p className=" text-pr">{Number(userAmount).toFixed(4)} BNB</p>
                  </div>
                  <div className=" w-full flex items-center justify-between pt-2">
                    <p className=" text-pr">Your Miners</p>
                    <p className=" text-pr">{MinerAmount}</p>
                  </div>
                  <div className=" mt-8 w-full flex items-center justify-center">
                    <div className=" px-16 text_osa rounded-xl text-lg py-3 text-gray-400">
                      <input placeholder="0" className="inp" style={{marginLeft: "5px"}} onChange={(e) => setbnbamount(e.target.value)} onKeyPress={(event) => {
        if (!/[0-9-.]/.test(event.key)) {
          event.preventDefault();
        }
      }}>
                      </input>
                      <span> BNB</span>
                    </div>
                  </div>
                  <div className=" mt-6 w-full flex items-center justify-center">
                    <button
                      className="  btn-primary-sm"
                      hidden={loading}
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "8px",
                        paddingBottom: "15px",
                      }}
                      onClick={() => insertCoin()}
                    >
                      Insert Coin
                    </button>
                  </div>
                  <div className=" w-full flex items-center justify-between" style={{paddingTop: "20px",}}>
                    <p hidden={loading} className=" text-red-600 text_osa">Your Rewards</p>
                    <p hidden={loading} className=" text-pr">{yourRewards} BNB</p>
                  </div>
                  <div className=" mt-9 lg:mt-14 gap-2 w-full flex items-center justify-center">
                  <PacmanLoader hidden={!loading} color="yellow" loading={loading} size={35} />
                    <button
                      className="  btn-primary-sm"
                      hidden={loading}
                      style={{
                        fontSize: "12px",
                        paddingLeft: "29px",
                        paddingRight: "29px",
                        paddingTop: "8px",
                        paddingBottom: "15px",
                      }}
                      onClick={() => compound()}
                    >
                      Compound
                    </button>
                    <button
                      className="  btn-primary-sm"
                      hidden={loading}
                      style={{
                        fontSize: "12px",
                        paddingLeft: "29px",
                        paddingRight: "29px",
                        paddingTop: "8px",
                        paddingBottom: "15px",
                      }}
                      onClick={() => cashout()}
                    >
                      Cash Out
                    </button>
                  </div>
                </div>
              </div>
              {buy === false && (
                <button
                  onClick={function(event) {setBuy(true); setlinkchainbits("https://buy.chainbits.com/?crypto=BNB&amp;fiat=USD&amp;amount=100")}}
                  className="  btn-primary mt-8 w-full"
                  style={{
                    marginTop: "50px",
                    fontSize: "19px",
                    paddingTop: "8px",
                    paddingBottom: "15px",
                  }}
                >
                  Buy BNB With Credit Card
                </button>
              )}
          
               
            
              <iframe
                title="chainbits widget"
                src={linkchainbits}
                frameBorder="false"
                allowtransparency="true"
                defaultcryptocurrency="BNB"
                className="custom_animation my-10"
                style={buy ? { width: "100%", height:"500px" }: {height:"0px", overflow:" hidden"}}
              />
              <div className=" rounded-3xl border-4 border-sr p-4 w-full my-10">
                <h1 className=" text-2xl text-gray-400 text-center text_osa">
                  Pac-Miner Facts
                </h1>
                <div className=" w-full flex items-center justify-between my-5">
                  <p className=" text-pr text-lg">Daily Return</p>
                  <p className=" text-red-700 text-lg">1.5%</p>
                </div>
                <div className=" w-full flex items-center justify-between my-5">
                  <p className=" text-pr text-lg">ROI</p>
                  <p className=" text-red-700 text-lg">547%</p>
                </div>
                <div className=" w-full flex items-center justify-between my-5">
                  <p className=" text-pr text-lg">Dev Fees</p>
                  <p className=" text-red-700 text-lg">2.5%</p>
                </div>
              </div>
              

              <div className=" rounded-3xl border-4 border-sr p-4 w-full my-10">
                <h1 className=" text-2xl text-gray-400 text-center text_osa">
                Income From Referrals
                </h1>
                <p className="text-red-700 text-lg" align="right" hidden={!refhidden} onClick={() => {setrefhidden(false); setbtnclicked(null);}}>❌ Close</p>
                <div className=" flex items-center justify-center my-10">
                <button className=" btn-primary-sm" style={{fontSize:"14px", paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "8px",
                        paddingBottom: "15px",}}
                        hidden={refhidden}
                        onClick={() => {loadingrefresult(1); setrefhidden(true); setbtnclicked(1);}}>
                        Level 1
                        </button>
                        <button className=" btn-primary-sm" style={{fontSize:"14px", paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "8px",
                        paddingBottom: "15px",}}
                        hidden={refhidden}
                        onClick={() => {loadingrefresult(2); setrefhidden(true); setbtnclicked(2);}}>
                        Level 2
                        </button>
                        </div>
                        <div className=" flex items-center justify-center my-10">

                        <button className=" btn-primary-sm" style={{fontSize:"14px", paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "8px",
                        paddingBottom: "15px",}}
                        hidden={refhidden}
                        onClick={() => {loadingrefresult(3); setrefhidden(true); setbtnclicked(3);}}>
                        Level 3
                        </button>
                        <button className=" btn-primary-sm" style={{fontSize:"14px", paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "8px",
                        paddingBottom: "15px",}}
                        hidden={refhidden}
                        onClick={() => {loadingrefresult(4); setrefhidden(true); setbtnclicked(4);}}>
                        Level 4
                        </button>
                        <p hidden={!refhidden}>Referral Level {btnclicked} </p>
                        </div>
                        <div className=" my-5">
                        <input hidden={!refhidden} disabled value={showrefresult + " Ghosts" } className=" py-4 w-full px-3 border-2  text_osa border-sr rounded-xl outline-none bg-transparent">
                        </input>
                        </div>
              </div>

              <div className=" rounded-3xl border-4 border-sr p-4 w-full my-10">
                <h1 className=" text-2xl text-gray-400 text-center text_osa">
                  Referral Link
                </h1>
                <div className=" my-5">
                  <input
                    type="text"
                    disabled
                    value={`https://pacminer.io/?ref=${realAddress}`}
                    className=" py-4 w-full px-3 border-2  text_osa border-sr rounded-xl outline-none bg-transparent"
                  />
                </div>
               <div className=" flex items-center justify-center my-10">
<button className=" btn-primary-sm" style={{fontSize:"14px", paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "8px",
                        paddingBottom: "15px",}}
                        onClick={() => {navigator.clipboard.writeText(`https://pacminer.io/?ref=${realAddress}`); //MORE
                          toast.success("Copied", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          });
                          }}>
                        COPY LINK
                        </button>
               </div>
               <p className=" px-2 text-pr pb-4 text-center">Earn bonus coins inserted in the miner from anyone who uses your referral link and from their own referrals</p>
               <div className=" w-full flex items-center justify-between my-1">
                  <p className=" text-red-700 text-lg">Level 1: (6%)</p>
                  <p className=" text-pr text-lg">Referral Bonus</p>
                </div>
               <div className=" w-full flex items-center justify-between my-1">
                  <p className=" text-red-700 text-lg">Level 2: (3%)</p>
                  <p className=" text-pr text-lg">Referral Bonus</p>
                </div>
               <div className=" w-full flex items-center justify-between my-1">
                  <p className=" text-red-700 text-lg">Level 3: (2%)</p>
                  <p className=" text-pr text-lg">Referral Bonus</p>
                </div>
               <div className=" w-full flex items-center justify-between my-1">
                  <p className=" text-red-700 text-lg">Level 4: (1%)</p>
                  <p className=" text-pr text-lg">Referral Bonus</p>
                </div>
              </div>
              <div className=" rounded-3xl border-4 border-sr p-4 w-full my-10">
                <p align="center" style={{fontSize: 10}}>Pac-Miner is the ultimate crypto-yield dApp inspired by the most famous videogame ever. Simply insert your BNB coin in the miner and start earning passive income. The Pac-Miner contract is structured to promote slow and steady profit for all its investors, with minimal dev-fee and a well thought anti-whale and anti-draining mechanism. Pac-miner also features a 4 levels upline, so if you refer your friends, and they refer their own friends, you can make profit up to 4 grades of connection. If you want to learn more, please check this PDF. Please note, Pac-miner is unsupported in the following jurisdictions: United States, UAE, China, Canada, South and North Korea, Iran, Syrian Arab Republic, Cuba, Crimea, Donetsk, Luhansk. If you are a citizen or resident of one of those jurisdictions, please do not interact with Pac Miner. We have set up a geoblock to prevent misuse from unauthorized users: please do not try to bypass it, as we might have to block your account due to breach of our T&C.</p>
              </div>
              <div className=" flex items-center justify-center gap-5 my-10">
              <p hidden={!isMetamask} className = "px-2 text-pr pb-4 text-center" onClick={()=> addNetwork()}>
                  add BSC network on metamask
              </p>
              </div>
              <div className=" flex items-center justify-center gap-5 my-10">
<a href={`https://bscscan.com/address/${contractAddress}`}><img src={net} className=" w-10" alt="" /></a>
<a href="https://t.me/PacMiner"><img src={tele} className=" w-10" alt="" /></a>
<a href="https://twitter.com/pacminerbsc"><img src={twitter} className=" w-10" alt="" /></a>
              </div>
            </>
          )}
        </div>
        {connect && (
          <button
            className=" btn-primary-sm  text-white hidden lg:block absolute top-24 py-3 right-10 z-10"
            style={{ fontSize: "14px" }}
            onClick={()=> window.open(`https://bscscan.com/address/${realAddress}`, "_blank")}
          >
            {userAddress}
          </button>
        )}
      </div>
    </div>
  );
}

export default App;
